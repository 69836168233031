@use '../../../styles/_global-variables.scss' as global;

.xarxesHeader {
    font-size: var(--headline_xs);
    margin-bottom: 1em;
    text-align: center;
    @media #{global.$VP_ALL_MOBILE} {
        font-size: var(--headline_s);
    }

    &.petit-left-header,
    &.petit-right-header {
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
        text-align: left;

        @media #{global.$VP_ALL_MOBILE} {
            font-size: 17px;
        }
    }
}

.social {
    display: flex;
    column-gap: 16px;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 16px;
    margin-bottom: var(--gapRow);
    a {
        width: 32px;
        height: 32px;
        display: inline-block;
        opacity: 0.8;
    }
}

.petit-left,
.petit-right {
    justify-content: left;
    column-gap: 18px;
    a,
    .iconaSocial {
        width: 32px;
        height: 32px;
    }
}

.petit-right {
    justify-content: right;
}

@media #{global.$VP_BEYOND_MOBILE} {
    .iconaSocial,
    .social a {
        width: 40px;
        height: 40px;
    }
    .social {
        column-gap: 32px;
    }

    .petit-left,
    .petit-right {
        column-gap: 18px;
        a,
        .iconaSocial {
            width: 32px;
            height: 32px;
        }
    }
}

.social a:hover {
    opacity: 1;
}
