@use '../../../styles/_global-variables.scss' as global;

.destacatWebview {
    position: relative;
    color: var(--clr_trans_0);
    //min-height: 400px;
    max-width: 100%;
    margin-bottom: var(--gapRow);
    &.pellicula .descripcio {
        margin-top: -202px;
    }
    .logo {
        height: 62px;
    }
    .boto {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0 4px;
        height: 20px;
        width: 63px;
        border: 1px solid #fff;
        border-radius: 4px;
        margin-right: 16px;
        margin-bottom: 16px;
        font-size: var(--body_xxs);
        pointer-events: all;
    }
    .botons {
        margin-bottom: 16px;
    }
    .subtitols {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }
    .subtitols object {
        width: 20px;
        height: 20px;
    }
    .txt_subtitols {
        font-size: var(--body_xs);
        line-height: 1.35;
        margin-left: 8px;
        margin-top: 2px;
        margin-bottom: 0;
    }
    .botoPlay {
        margin-top: 24px;
        margin-right: 18px;
        background-color: var(--clr_portal_100);
    }
    .pubdate {
        font-size: var(--body_s);
        margin-left: 0;
    }
    .dadesVideo {
        height: auto;
        display: flex;
        gap: 16px;
        align-items: center;
        flex-wrap: wrap;
    }
    .dadesVideoContinuar {
        margin-bottom: 0;
        margin-top: 16px;
    }

    .wrapperEntradeta {
        position: relative;
        margin-bottom: 12px;
    }
    .descripcio {
        margin-bottom: 0;
        h2 {
            margin: 8px 0;
        }
    }
    .ico_ad {
        margin-right: 6px;
    }
    .entradeta {
        font-size: var(--body_m);
        line-height: 24px;
        font-weight: 400;
        overflow: hidden;
        margin-top: 16px;
        margin-bottom: 0;
        display: block;
        transition-property: all;
    }
    .entradetaPeli {
        font-size: var(--body_m);
        line-height: 24px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 16px;
    }
    .mes {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 26px;
        width: 65px;
        text-align: right;
        background: linear-gradient(270deg, rgb(17, 17, 17) 45px, rgba(17, 17, 17, 0) 100%);
        display: none;
        cursor: pointer;
        span {
            border-bottom: 1px solid;
        }
    }
}

.destacat {
    position: relative;
    color: #fff;
    min-height: 458px;
    max-width: 2144px;
    margin-bottom: var(--gapRow);
    height: 32vw;

    &:not(.peliDestacat, .bigDestacat, .horitzontal) h2 {
        //padding-top: 80px;
        @media #{global.$VP_LARGE_NEGATION} {
            padding-top: 0;
        }
    }
    h2.titol_directe {
        padding-top: 0;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 50%;
        top: 0;
        bottom: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        z-index: 1;
    }
}
.textEntradeta {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
}
.titolSenseLogo {
    font-weight: 300;
}
.titolSenseLogoBold {
    font-weight: 700;
}
.infoDistribucio {
    display: table;
    padding: 4px 8px;
    margin-bottom: 16px;
    border-radius: 4px;
    font-weight: 700;
    background: rgba(17, 17, 17, 0.6);
    color: global.$color10;
    font-size: var(--body_m);
    @media #{global.$VP_ALL_MOBILE} {
        font-size: var(--body_xs);
    }
}
.logoSharing {
    display: inline-flex;
    gap: 8px;
    column-gap: 8px;
    align-items: flex-end;
}
.sharingTop {
    margin-top: 6px;
    margin-bottom: 16px;
}

.logodirecte {
    height: 70px;

    @media #{global.$VP_LARGE_NEGATION} {
        height: 43px;
    }
}

.logo {
    height: 124px;
    max-width: 310px;
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    img {
        margin: 0 !important;
        width: auto !important;
        min-width: auto !important;
    }
}

.bigDestacat {
    max-height: 1080px;
    min-height: 570px;
    max-width: 2144px;
    &:before,
    &:after {
        content: none;
    }
    h2 {
        padding-top: 0;
    }
    .logo {
        margin-bottom: 32px;
        //height: 88px;
    }
    .cos {
        height: auto;
    }
    .imatgeWrapper {
        width: 82%;
        margin-left: 18%;
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
        &:before {
            background: linear-gradient(180deg, rgba(17, 17, 17, 0) 82.73%, rgba(17, 17, 17, 0.6) 92.07%, #111111 98.02%);
        }
        &:after {
            background: linear-gradient(
                    90deg,
                    rgb(17, 17, 17) 0%,
                    rgba(17, 17, 17, 0.85) 76px,
                    rgba(17, 17, 17, 0.7) 125px,
                    rgba(17, 17, 17, 0) 250px
                ),
                linear-gradient(45deg, rgb(17, 17, 17) 0%, rgba(17, 17, 17, 0) 145px);
        }
    }
}

.sharing {
    width: 100%;
}

.pretitol {
    padding-top: 0;
}

.programa {
    height: auto;
    aspect-ratio: 32 / 15;
    max-width: 100%;
    max-height: 1080px;
    z-index: 2;
    overflow: hidden;
    min-height: 458px;
    .descripcio {
        // h2 {
        //     font-size: var(--headline_xs);
        //     line-height: 1.625;
        //     font-weight: 500;
        //     flex-basis: 100%;
        // }
        p {
            font-size: var(--body_m);
            line-height: 1.31;
            flex-basis: 100%;
        }
        &:after {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    &:before {
        content: none;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.88%, #111111 96.87%),
            radial-gradient(
                52.15% 164.83% at 55.76% 50.07%,
                rgba(17, 17, 17, 0) 0%,
                rgba(17, 17, 17, 0.3) 76.43%,
                rgba(17, 17, 17, 0.8) 100%
            );
    }
}

.destacatProgramaWebView {
    margin-bottom: 35px;
    aspect-ratio: auto;
    .descripcio h2 {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 8px;
    }
    .boto {
        margin-top: 0;
    }
}
.pubdate {
    margin-left: 12px;
}
.cos {
    height: 100%;
    display: block;
    overflow: hidden;
}
.imatgeWrapper {
    max-width: 2560px;
    width: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
}
.bigDestacat .imatgeWrapper,
.programa .imatgeWrapper {
    position: relative;
}
.imatge span {
    display: none;
}
.logoSupertitol {
    position: absolute;
    top: 138px;
    left: 56px;
    z-index: 2;
    object {
        top: 0;
        left: 0;
    }
}
.logoSupertitol .eva_blanc {
    width: 152px;
    height: 74px;
}
.logoSupertitol .sx3 {
    width: 137px;
    height: 69px;
}
.supertitol {
    position: absolute;
    top: 138px;
    left: 56px;
    z-index: 2;
    font-weight: 100;
    font-size: 4.6rem;
    line-height: 1;
    color: var(--clr_trans_20);
    max-width: 40%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    &:after {
        content: '';
        position: absolute;
        top: -15px;
        right: -15px;
        bottom: -15px;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
        filter: blur(30px);
        z-index: -1;
    }
}
/*.link {
    order: 3;
}*/
.avantitol {
    font-size: var(--body_xxs);
    color: #000;
    background: rgba(255, 255, 255, 0.8);
    display: table;
    padding: 6px;
    font-weight: 500;
    //order: 3;
    margin-top: 16px;
    border-radius: 4px;
    text-transform: uppercase;
}
.movieLabel {
    display: table;
    padding: 4px 8px;
    margin-top: 4px;
    margin-bottom: 16px;
    border-radius: 4px;
    font-weight: 500;
    background: global.$color0;
    color: global.$color100;
    text-transform: uppercase;
    font-size: var(--body_xxs);
}
.descripcio {
    position: absolute;
    width: 50%;
    left: 0;
    bottom: 0;
    padding: 0 0 42px 56px;
    padding-bottom: 42px;
    z-index: 5;
    &:after {
        content: '';
        position: absolute;
        top: -15px;
        right: -15px;
        bottom: -15px;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 150px;
        filter: blur(30px);
        z-index: -1;
    }
    &.ambLogo:after {
        top: 125px;
    }
    h2 {
        font-weight: 300;
        font-size: var(--headline_m);
        line-height: 1.2;
        margin: 24px 0 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-bottom: 2px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    }
    a:hover h2 span {
        border-bottom: 1px solid currentColor;
    }
    p {
        font-size: var(--body_m);
        line-height: 1.41;
        margin-bottom: 9px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        //order: 5;
    }
}
.dadesVideoContinuar {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-direction: column;
    width: 160px;
    span {
        font-size: var(--body_s);
    }
    .progress {
        margin-top: 6px;
        width: 160px;
    }
}
.dadesVideo {
    display: flex;
    align-items: center;
    height: 20px;
    color: var(--clr_trans_10);
    margin-bottom: 16px;
    font-size: var(--body_s);
    line-height: 1;
    //order: 6;
    .progress {
        width: 174px;
        margin-left: 16px;
    }
}
.ico_clock {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    vertical-align: middle;
    margin-top: -2px;
}
.ico_codi {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}
.social {
    position: absolute;
    right: 66px;
    bottom: 50px;
    z-index: 1;
    object {
        width: 20px;
        height: 20px;
        opacity: 0.8;
    }
}

.social a:hover object {
    opacity: 1;
}

.boto {
    margin-top: 15px;
    margin-right: 26px;
    //order: 7;
}

.horitzontal {
    min-height: 0;
    height: auto;
    &::after,
    &::before {
        display: none;
    }

    a {
        display: grid;
        grid-template-columns: 1fr 2.6fr;
        &:hover h2 span {
            border-bottom: 1px solid currentColor;
        }
    }

    .h_text {
        padding-left: 16px;
        padding-right: 20%;
        line-height: 1.3;
    }
    .h_avantitol {
        font-size: var(--body_xs);
        text-transform: uppercase;
        padding-bottom: 8px;
    }
    .h_titol {
        font-size: var(--headline_xs);
        padding-bottom: 8px;
        padding-top: 0;
    }
    .h_entradeta {
        font-size: var(--body_m);
    }
}
.noticiaDestacada {
    height: auto;
    a {
        display: grid;
        grid-template-columns: 2fr 2fr;
    }
}
@media #{global.$VP_ALL_MOBILE} {
    .destacat {
        min-height: 394px;
        height: auto;
        &:before,
        &:after {
            content: none;
        }
    }
    .estrena {
        min-height: 357px;
        margin-bottom: 8px;
    }
    .descripcio {
        width: 100%;
        padding: 0 16px;
        position: relative;
        order: 2;
        margin-top: -130px;
        .avantitol {
            display: none;
        }
        .dadesVideo {
            margin-bottom: 6px;
            .progress {
                width: 50%;
                margin-top: 2px;
            }
        }
    }
    .cos {
        display: flex;
        flex-direction: column;
    }
    .logo {
        height: 64px;
        max-width: 200px;
    }
    .supertitol {
        display: none;
    }
    .destacatWebview .descripcio {
        .ico_codi {
            display: inline-block;
            vertical-align: bottom;
        }
        .dadesVideo {
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
    }
    .bigDestacat {
        .imatgeWrapper {
            width: 100%;
            margin-left: 0;
            height: 400px;
            &:before {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                background: linear-gradient(179.49deg, rgba(0, 0, 0, 0) 60.97%, rgba(8, 8, 8, 0.6) 76.54%, #111111 94.13%),
                    radial-gradient(
                        53.43% 168.86% at 54.02% 50%,
                        rgba(17, 17, 17, 0) 0%,
                        rgba(17, 17, 17, 0) 51.87%,
                        rgba(17, 17, 17, 0.9) 99.11%
                    );
            }
            &:after {
                content: none;
            }
        }
        .logo {
            margin-bottom: 10px;
        }
    }
    .programa {
        height: auto;
        aspect-ratio: auto;
        &:after {
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #111111 93.57%),
                radial-gradient(
                    103.61% 327.46% at 103.61% 50%,
                    rgba(17, 17, 17, 0) 0%,
                    rgba(17, 17, 17, 0.3) 76.43%,
                    rgba(17, 17, 17, 0.8) 100%
                );
        }
        .social {
            display: none;
        }
        .boto {
            margin-top: 8px;
        }
        .descripcio {
            margin-top: -60vw;
        }
        .imatgeWrapper {
            aspect-ratio: 360 / 373;
        }
    }
    .destacatEsticVeient {
        height: auto;

        .boto {
            margin-bottom: 20px;
        }
    }
    .imatgeWrapper {
        aspect-ratio: 45 / 35;
        &:before {
            content: '';
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: 1;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #111 93.57%);
        }
    }
}

.sinopsi {
    padding: 16px;
    margin-bottom: var(--gapRow);
    margin-top: calc(var(--gapRow) * -1);
}
.sinopsi_webview {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.sinopsi_titol {
    font-weight: 300;
    font-size: var(--headline_xs);
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 16px;
}
.sinopsi_entradeta {
    margin-bottom: 48px;
    p {
        line-height: 1.3;
    }
}
.sinopsi_text {
    &.peli {
        font-size: var(--body_s);
        line-height: 1.5;
    }
    p {
        line-height: 1.3;
        margin-bottom: 10px;
    }
}

.durada {
    display: flex;
    align-items: center;
    gap: 3px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
}
@media #{global.$VP_TP} {
    .bigDestacat {
        height: 580px;
        .cos {
            display: flex;
        }
    }
    .descripcio {
        width: 80%;
        padding-left: 34px;
    }
    .supertitol {
        max-width: 50%;
        font-size: 4rem;
        left: 34px;
    }
    .programa {
        .descripcio p {
            display: none;
        }
    }
}

@media #{global.$VP_MOBILE} {
    .destacat .descripcio h2 {
        line-height: 1.2;
    }
    .destacat .horitzontal {
        min-height: 0;
    }
    .sinopsi_entradeta {
        margin-bottom: 22px;
    }
}

@media #{global.$VP_BEYOND_MOBILE} {
    .destacatWebview {
        .cos {
            display: flex;
            flex-flow: column-reverse;
        }
        .logo {
            height: 88px;
        }
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            aspect-ratio: 16 / 9;
            left: 0;
            z-index: 1;
            background: linear-gradient(180deg, rgba(17, 17, 17, 0) 71.73%, rgba(17, 17, 17, 0.6) 84.07%, #111111 98.02%),
                radial-gradient(
                    56.36% 178.11% at 56.94% 50%,
                    rgba(17, 17, 17, 0) 0%,
                    rgba(17, 17, 17, 0.3) 41.22%,
                    #111111 99.11%
                );
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            aspect-ratio: 16 / 9;
            left: 0;
            z-index: 1;
            background: linear-gradient(90deg, rgb(17, 17, 17) 0%, rgba(17, 17, 17, 0) 50px),
                linear-gradient(90deg, rgba(17, 17, 17, 0) calc(100% - 50px), rgb(17, 17, 17) 100%);
        }
    }
    .destacatProgramaWebView {
        min-height: 576px;
        .textEntradeta {
            margin-bottom: 32px;
        }
        .sharingTop {
            margin-bottom: 56px;
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            height: 200px;
            background: linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(17, 17, 17, 0) 100%);
            z-index: 2;
        }
    }
}

@media (max-width: 670px) {
    .horitzontal {
        min-height: 0;
        height: auto;
        a {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
        }
        .h_text {
            padding: 8px 0;
        }
        .h_entradeta {
            display: none;
        }
    }
}

@media #{global.$VP_MOBILE_AND_TP} {
    .bigDestacat {
        aspect-ratio: unset;
    }
    .logo {
        height: 62px;
    }
    .titolSenseLogoBold {
        font-size: var(--body_l);
    }
    .titolSenseLogo,
    .destacat .descripcio h2 {
        font-size: var(--headline_s);
    }
}

@media #{global.$VP_ALL_MOBILE} {
    .logoSharing {
        display: inline-block;
    }
    .programa,
    .bigDestacat {
        .logoSharing {
            display: inline-block;
        }
    }
    .sharing {
        width: auto;
        display: inline-block;
        margin-left: 8px;
    }
    .titolSenseLogo,
    .destacat .descripcio h2 {
        font-size: var(--headline_m);
    }
}

@media #{global.$VP_BEYOND_TP} {
    .titolSenseLogoBold {
        font-size: var(--headline_xs);
    }
    .titolSenseLogo,
    .destacat .descripcio h2 {
        font-size: var(--headline_s);
    }
    .bigDestacat {
        aspect-ratio: 144 / 67;
        height: auto;
        max-width: 100%;
    }
    .supertitol {
        font-size: 4rem;
        line-height: 4rem;
    }
    .logoSupertitol.eva_blanc {
        width: 74px;
    }
    .logoSupertitol.sx3 {
        width: 60px;
    }
}

@media #{global.$VP_REGULAR_AND_TL} {
    //'(min-width: 1024px) and (max-width: 1279px)';
    .logo {
        height: 100px;
    }
    .bigDestacat .logo {
        height: 62px;
    }
}

@media #{global.$VP_LARGE} {
    .bigDestacat .logo {
        height: 100px;
    }
}

@media #{global.$VP_EXTRA_LARGE_NEGATION} {
    .supertitol {
        top: 90px;
    }
}

@media #{global.$VP_EXTRA_LARGE} {
    .titolSenseLogoBold {
        font-size: var(--headline_s);
    }
    .titolSenseLogo,
    .destacat .descripcio h2 {
        font-size: var(--headline_m);
    }
    .logoSupertitol.eva_blanc {
        width: 100px;
    }
    .logoSupertitol.sx3 {
        width: 90px;
    }
    .descripcio .textEntradeta {
        font-size: var(--body_l);
    }
}

@media #{global.$VP_SUPER_EXTRA_LARGE} {
    .titolSenseLogoBold {
        font-size: var(--headline_m);
    }
    .titolSenseLogo {
        font-size: var(--headline_l);
    }
    .destacat .descripcio h2 {
        font-size: var(--headline_l);
        line-height: 68px;
    }
    .bigDestacat {
        aspect-ratio: unset;
        overflow: hidden;
        .logo {
            height: 124px;
        }
    }

    .logoSupertitol.eva_blanc {
        width: 152.15px;
    }
    .logoSupertitol.sx3 {
        width: 137px;
    }
}

@media #{global.$VP_MASTODONTIC} {
    .destacat {
        aspect-ratio: 770 / 229;
        height: auto;
        margin-inline: auto;
    }
    .bigDestacat {
        aspect-ratio: unset;
        overflow: hidden;
        .imatgeWrapper:after {
            background: linear-gradient(
                    90deg,
                    rgb(17, 17, 17) 0%,
                    rgba(17, 17, 17, 0.85) 76px,
                    rgba(17, 17, 17, 0.7) 125px,
                    rgba(17, 17, 17, 0) 250px
                ),
                linear-gradient(45deg, rgb(17, 17, 17) 0%, rgba(17, 17, 17, 0) 145px),
                linear-gradient(270deg, rgb(17, 17, 17) 40px, rgba(17, 17, 17, 0.2) 150px, rgba(17, 17, 17, 0) 10%),
                linear-gradient(-45deg, rgb(17, 17, 17) 0%, rgba(17, 17, 17, 0) 145px);
        }
    }
    .programa {
        aspect-ratio: 32 / 15;
    }
}

@media #{global.$VP_MASTODONTIC_EXTRA} {
    .destacat {
        max-width: 2032px;
    }
    .bigDestacat {
        max-width: 2144px;
    }
}

@media #{global.$VP_INGENT} {
    .destacat {
        max-width: 2144px;
    }
    .bigDestacat {
        max-width: 2256px;
    }
}

@media (min-width: 768px) and (max-width: 1620px) {
    .documentals {
        .supertitol {
            font-size: 2.85rem;
            max-width: 60%;
            text-shadow: 10px 5px 5px rgba(0, 0, 0, 0.5);
        }
        .cos:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 200px;
            background: linear-gradient(
                0deg,
                rgba(17, 17, 17, 0) 0%,
                rgba(17, 17, 17, 0.4) 26%,
                rgba(17, 17, 17, 0.4) 47%,
                rgba(17, 17, 17, 0.3) 100%
            );
            z-index: 1;
        }
    }
}

@media (min-width: 768px) and (max-width: 1390px) {
    .documentals {
        .logo {
            height: 88px;
        }
        .supertitol {
            top: 90px;
            max-width: 80%;
        }
    }
}

@media #{global.$VP_TP} {
    .documentals .supertitol {
        top: 60px;
    }
    .logoSupertitol.eva_blanc {
        width: 68px;
    }
    .logoSupertitol.sx3 {
        width: 60px;
    }
}

@media (min-width: 2560px) {
    .titolSenseLogoBold {
        font-size: var(--headline_l);
    }
    .titolSenseLogo,
    .destacat .descripcio h2 {
        font-size: var(--headline_xl);
    }
}

//FIX SAFARI
@supports not (aspect-ratio: 16 / 9) {
    .destacat .imatgeWrapper {
        padding-top: calc(100% / 16 * 9);
    }
}
