@use '../../../styles/_global-variables.scss' as global;

.compartir {
    pointer-events: all;
    flex: 0 1;

    .overlay {
        position: fixed;
        top: calc(50% - 124px);
        left: calc(50% - 210px);
        width: 420px;
        padding: 16px;
        background-color: global.$color80;
        z-index: 15;

        .btn_tanca {
            cursor: pointer;
            pointer-events: all;
            position: absolute;
            top: 18px;
            right: 18px;

            .ico_tanca {
                width: 20px;
                height: 20px;
            }
        }

        .titol {
            font-size: var(--body_m);
            font-weight: 700;
            margin: 0 0 24px;
            text-shadow: none;
            padding: 0;
            line-height: normal;
        }

        .links {
            display: flex;
            justify-content: center;
            margin-bottom: 34px;

            .link {
                width: 80px;
                height: 80px;
                margin: 0 12px;
                font-size: var(--body_xs);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                opacity: 0.7;
                transition: all 0.2s;
                cursor: pointer;
                &:hover {
                    opacity: 1;
                }

                .iconaSocial {
                    margin-bottom: 12px;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}
.boto {
    cursor: pointer;
    .icona {
        opacity: 0.8;
        width: 32px;
        height: 32px;
    }
    &:hover .icona {
        opacity: 1;
    }
}
.mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 15;
}

.embed {
    display: flex;
    gap: 10px;
    max-width: 340px;
    margin: 0 auto 24px;
}
.input {
    color: global.$color10;
    background-color: global.$color70;
    border: 2px solid global.$color60;
    flex-grow: 1;
    padding: 8px 16px;
}
.btn_copia {
    margin: 0 auto;
    width: 80px;
    text-align: center;
    display: block;
    transition: all 0.5s;
    &:active {
        background: #000;
        transition: all 0s;
    }
}
@media #{global.$VP_SUPER_EXTRA_LARGE} {
    .btn_copia {
        width: 108px;
    }
}
@media (max-width: 460px) {
    .overlay {
        width: auto;
        left: 20px;
        right: 20px;
    }
}
